import React from 'react'
import { Container, Flex, Box, Badge, css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ListItem from '@solid-ui-components/ListItem'
import ContentText from '@solid-ui-components/ContentText'
import ContentContainer from '@solid-ui-components/ContentContainer'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import SignupForm from '../SignupForm'

const styles = {
  saveBadge: {
    position: `absolute`,
    top: 3,
    right: 3,
  }
}

const Pricing = ({ content: { text, buttons, collection }, formContent }) => {

  return (
    <Container sx={{
        textAlign: `left`,
        marginTop: ['100px', '150px']
    }}>
        <Box sx={{ textAlign: `center` }}>
            <ContentText content={text?.slice(0, 3)} />
        </Box>
        <Divider space={4} />
        <Reveal
            effect='fadeInUp'
        >
            <ContentContainer
                variant='cards.paper'
                sx={{ position: `relative`, p: [3, 4] }}
            >
                <ContentText
                    content={collection[0].text?.[0]}
                    mb='1'
                    sx={{ marginTop: [`30px`, `0`, `0`, `0`] }}
                />
                <Flex
                    css={{ justifyContent: `space-between` }}
                    sx={{
                        alignItems: [`center`, `center`, `center`, `flex-start`],
                        flexDirection: [`column`, `column`, `column`, `row`]
                    }}
                >
                    <Box
                        sx={{
                            width: [`100%`, `95%`, `90%`, `53%`],
                            marginBottom: ['40px', null],
                        }}
                    >
                        <Flex
                            css={{
                                alignItems: `center`,
                                justifyContent: `flex-start`
                            }}
                        >
                            <ContentText content={collection[0].text?.[1]} mb='0' mr='2' ml='0' />
                            <ContentText content={collection[0].text?.[2]} mb='0' mt='2' />
                        </Flex>

                        <Divider space={2} />
                        {collection[0].collection.map((props, index) => (
                            <ListItem key={`item-${index}`} {...props} compact />
                        ))}
                        <Divider space={2} />
                        <ContentButtons content={collection[0].buttons} />
                    </Box>
                    <Box
                        sx={{
                            width: [`100%`, `90%`, `80%`, `47%`]
                        }}
                    >
                        <ContentContainer content={{
                                variant: "cards.paper",
                                bg: "omegaDarker"
                            }}
                            sx={{ p: [3, 4] }}
                        >
                            <Box sx={{ textAlign: `center` }}>
                                <ContentText content={formContent.text} />
                                <Divider space={3} />
                            </Box>
                            <SignupForm
                                content={formContent}
                            />
                        </ContentContainer>
                    </Box>
                </Flex>
                    
            </ContentContainer>
        </Reveal>
    </Container>
  )
}

export default WithDefaultContent(Pricing)
