import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import Pricing from '../../components/pricing/Pricing'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Faq from '@solid-ui-blocks/Faq/Block02'
import Download from '@solid-ui-blocks/CallToAction/Block02'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import GatsbyConfig from '../../../gatsby-config';
import styles from './_styles'

const PricingPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo
        title='Pricing'
        description={`Our pricing sets us apart. Review Generation - get reviews or it's free.`}
        siteUrl={GatsbyConfig.siteMetadata.siteUrl}
        thumbnail={{
          hero: {
            src: '/srm_acronym_logo.png'
          }
        }}
      />
      {/* Blocks */}
      <Header />
      <Container variant='wide' sx={styles.pricesContainer}>
        <Pricing
          content={content['pricing']}
          formContent={content['signup-form']}
        />
      </Container>
      <Divider space='5' />
      <Container variant='narrow'>
        <Faq content={content['faq']} />
      </Container>
      <Divider space='5' />
      <Download content={content['cta']} />
      <Divider space='5' />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query pricingBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/pricing"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default PricingPage
