/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */


export default {
  pricesContainer: {
    bg: `omegaLighter`,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    py: 5,
    px: [4, 0]
  }
}
